import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Helmet } from 'react-helmet';

const AsesorMeeting = ({ open, onClose }) => {

  useEffect(() => {
    if (open) {
      const meetingsContainer = document.querySelector('.meetings-iframe-container');
      if (meetingsContainer) {
        const script = document.createElement('script');
        script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
        script.async = true;
        script.onload = () => {
          if (window.HubSpotConversations) {
            window.HubSpotConversations.widget.load();
          }
        };
        meetingsContainer.appendChild(script);
      }

      const script = document.createElement('script');
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.hbspt.forms.create({
          portalId: "48597852",
          formId: "a9b922bf-e636-4f0c-94a8-158a6cf5c307",
          target: "#form-container",
          onFormSubmit: () => {
            // No navegar a gracias
          }
        });
      };

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '98%',
        maxWidth: { xs: '100%', sm: '800px', md: '1200px' },
        height: '85vh',
        maxHeight: '95vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: { xs: 2, sm: 4 },
        borderRadius: '16px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: 2,
        overflowY: { xs: 'scroll', md: 'hidden' } // Permitir scroll en mobile
      }}>
        <Box sx={{ flex: 1, border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden' }}>
          <Helmet>
            <script
              type="text/javascript"
              src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
            ></script>
          </Helmet>
          <div className="meetings-iframe-container" data-src="https://service.zicofy.com/meetings/mattfuentes/zicoasis?embed=true" style={{ width: '100%', height: '100%', borderRadius: '8px', overflow: 'hidden' }}></div>
        </Box>
        <Box sx={{ flex: 1, display: { xs: 'none', md: 'block' } }}>
          <Helmet>
            <script
              type="text/javascript"
              src="//js.hsforms.net/forms/embed/v2.js"
            ></script>
          </Helmet>
          <div id="form-container" style={{ width: '100%', height: '100%', border: '1px solid #ccc', borderRadius: '8px', overflow: 'auto', padding: '20px', backgroundColor: '#f9f9f9' }}>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default AsesorMeeting;